<template>
    <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title">Neue Filialbestellung anlegen</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <h2 class="card-title">Verfügbare Filialen</h2>
                        </div>
                        <div class="card-body">
                            <select class="form-control form-control-sm" v-model="store">
                                <option :value="null">-- Filialen --</option>
                                <option v-for="store in stores" :key="store.id" :value="store.id">{{store.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <h3 class="card-title">Sonstiges</h3>
                        </div>
                        <div class="card-body">
                            <textarea class="form-control form-control-sm" v-model="info" rows="6"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-primary card-outline" style="height: 50vh">
                        <div class="card-header">
                            <h2 class="card-title">Verfügbare Artikel</h2>
                            <div class="card-tools">
                                <div class="input-group input-group-sm">
                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="getAllArticles">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body overflow-auto">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Artikelname</th>
                                        <th>Hersteller</th>
                                        <th>Preis</th>
                                        <th>Flags</th>
                                    </tr>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                        <th>
                                            <select v-model="params.producer_id" class="form-control form-control-sm">
                                                <option :value="''">-- Filter Hersteller --</option>
                                                <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.number }} - {{ producer.name  }}</option>
                                            </select>
                                        </th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(article,index) in articles.data" :key="article.id" :class="article.article.active == 0 ? 'table-danger' : ''" @dblclick="addArticle(index)">
                                        <td>{{ article.ordernumber }}</td>
                                        <td>{{ generateName(index) }}</td>
                                        <td>{{ article.article.producer.name }}</td>
                                        <td>{{ article.price | toCurrency}}</td>
                                        <td>
                                            <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                            <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                            <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                            <span class="ml-2 badge bg-warning" v-if="article.article.seasonal == 1">Saison </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                        <div class="clearfix align-middle card-footer">
                            <div class="row">
                                <div class="col-md-12">
                                    *rot hinterlegte Artikel sind deaktivierte Artikel. Sie können aber bei dem Lieferanten bestellt werden!
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                                    <select class="float-right form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Artikel</option>
                                        <option value="50">50 Artikel</option>
                                        <option value="75">75 Artikel</option>
                                        <option value="100">100 Artikel</option>
                                        <option value="125">125 Artikel</option>
                                        <option value="150">150 Artikel</option>
                                    </select>
                                    <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-success card-outline" style="height: 50vh">
                        <div class="card-header">
                            <h2 class="card-title">Bestellte Artikel</h2>
                        </div>
                        <div class="card-body overflow-auto">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Art. Nr.</th>
                                        <th>Artikelname</th>
                                        <th>Hersteller</th>
                                        <th>Preis</th>
                                        <th>Anzahl</th>
                                        <th>Filialregal Nullbestand</th>
                                        <th>Aktion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(article,index) in selectedArticles" :key="article.id">
                                        <td>{{ article.ordernumber }}</td>
                                        <td>{{ article.name }}</td>
                                        <td>{{ article.producer }}</td>
                                        <td>{{ article.price | toCurrency}}</td>
                                        <td><input type="number" class="form-control form-control-sm" v-model="selectedArticles[index].quantity" /></td>
                                        <td class="text-center">
                                            <input type="checkbox" v-model="selectedArticles[index].null_stock" true-value="1" false-value="0">
                                        </td>
                                        <td><button type="button" class="btn btn-danger btn-sm" @click="deleteArticle(index)"><i class="fas fa-trash"></i></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-right">
                <button type="button" class="btn btn-sm btn-primary" :disabled="selectedArticles.length <= 0 || store == null" @click="doOrder"><i class="fas fa-arrow-right"></i> Erstellen</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "supplier-orders.create",
    titel: "Filialbestellung",

    data(){
        return {
            producers: {},
            articles: {},
            stores: [],
            info: null,
            selectedArticles:[],
            finalOrders: [],
            store: null,
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'desc',
                per_page: 25,
                producer_id: '',
            },
            search: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },
    },

    methods: {
        doOrder(){
            this.finalOrders = [];
            var items = [];
            this.selectedArticles.forEach(function(item) {
                items.unshift({
                    'name': item.name,
                    'qty': item.quantity,
                    'han': item.han,
                    'ordernumber': item.ordernumber,
                    'article_detail_id': item.article_detail_id,
                    'null_stock': parseInt(item.null_stock)
                });
            });
            this.finalOrders.unshift({
                'store': this.store,
                'info': this.info,
                'items': items
            });
            this.axios
            .post("/store-orders", {
                orders: this.finalOrders,
            })
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Bestellung erstellt",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.$router.push({name: 'store-orders.index'});
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

        deleteArticle(index){
            this.$delete(this.selectedArticles, index);
        },

        addArticle(index){
            var article = this.selectedArticles.find(item => item.article_detail_id == this.articles.data[index].id);
            if(article == undefined)
            {
                this.selectedArticles.unshift({
                    ordernumber: this.articles.data[index].ordernumber,
                    name: this.generateName(index),
                    producer: this.articles.data[index].article.producer.name,
                    price: this.articles.data[index].price,
                    quantity: 1,
                    article_detail_id: this.articles.data[index].id,
                    null_stock: "0"
                });    
            }
            else
            {
                let temp;
                this.selectedArticles.forEach((element, index) => {
                    if(element.ordernumber == article.ordernumber)
                    {
                        temp = element;
                        this.selectedArticles.splice(index, 1);
                    }
                })
                temp.quantity += 1;
                this.selectedArticles.unshift(temp);
                // article.quantity += 1;
            }
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },

        getAllArticles(page = 1) {
            this.$Progress.start();
            this.axios
                .get("/details", {
                    params: {
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers")
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
        loadStores()
        {
            this.axios
                .get("/store-orders/pwa/stores")
                .then((response) => {
                    this.stores = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },
    
    mounted() {
        this.getAllArticles();
        this.getProducers();
        this.loadStores();
    }
}
</script>

<style>

</style>